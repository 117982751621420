@import "colors";
@import "mixins";

body {
	// font-family: 'Poppins', sans-serif;
	color: $color-text-a;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $color-a;
}

a {
	color: $color-a;
	transition: all .5s ease;

	&:hover {
		color: $color-b;
		text-decoration: none;
	}
}

.link-two {
	color: $color-a;
	transition: all .5s ease;

	&:hover {
		text-decoration: underline;
		color: $color-a;
	}
}

.link-one {
	color: $color-a;
	transition: all .5s ease;

	&:hover {
		color: $color-a;
		text-decoration: none;
	}
}

.link-icon {
	color: $color-a;
	font-weight: 500;

	span {
		font-size: 14px;
		padding-left: 4px;
		vertical-align: middle;
	}
}

.link-a {
	color: $color-w;
	text-decoration: none;

	@include breakpoint(md) {
		font-size: .9rem;
	}

	@include breakpoint(lg) {
		font-size: 1rem;
	}

	&:hover {
		color: $color-w;
		text-decoration: none;
	}

	span {
		font-size: 18px;

		@include breakpoint(md) {
			font-size: 1.5rem;
		}

		@include breakpoint(lg) {
			font-size: 18px;
		}

		vertical-align: middle;
		margin-left: 5px;
	}
}

.text-brand {
	color: $color-a;
	font-size: 2rem !important;
	font-weight: 600;

	@include breakpoint(md_max) {
		font-size: 1.8rem;
	}
}

.color-a {
	color: $color-a;
}

.color-b {
	color: $color-b;
}

.color-d {
	color: $color-d;
}

.color-text-a {
	color: $color-text-a;
}

.no-margin {
	margin: 0;
}

/*------/ Nav Pills  /------*/
.nav-pills-a {
	&.nav-pills {
		.nav-link {
			color: $color-a;
			position: relative;
			font-weight: 600;

			&.active {
				background-color: transparent;

				&:after {
					content: '';
					position: absolute;
					left: 0;
					bottom: -1px;
					width: 100%;
					height: 2px;
					background-color: $color-b;
					z-index: 2;
				}
			}
		}
	}
}

/*------/ Bg Image /------*/
.bg-image {
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-position: center center;
}

/*------/ List a /------*/
.list-a {
	@extend .ul-resect;
	display: inline-block;
	line-height: 2;
	padding: 0;
	list-style: none;

	li {
		position: relative;
		width: 50%;
		float: left;
		padding-left: 25px;
		padding-right: 5px;

		@include breakpoint(lg) {
			width: 33.333%;
		}

		&:before {
			content: '';
			width: 10px;
			height: 2px;
			position: absolute;
			background-color: $color-a-light;
			top: 15px;
			left: 0;
		}
	}
}

/*------/ Pagination-a /------*/
.pagination-a {
	.pagination {
		.page-link {
			margin: 0 .2rem;
			border-color: transparent;
			padding: .5rem .8rem;
			color: $color-a;

			&:hover,
			&:active,
			&:focus {
				background-color: $color-b;
			}

			span {
				font-size: 1.2rem;
			}

		}

		.next {
			.page-link {
				padding: .4rem .9rem;
			}
		}

		.page-item {
			&:last-child {
				.page-link {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}

			&:first-child {
				.page-link {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}

			&.disabled {
				.page-link {
					padding: .4rem .9rem;
					color: $color-text-a;
				}
			}

			&.active {
				.page-link {
					background-color: $color-b;
				}
			}
		}
	}
}

.a {
	color: $color-b;
	font-size: 1.3rem;
}

/*------/ Icon Box /------*/
.icon-box {
	.icon-box-icon {
		display: table-cell;
		vertical-align: top;
		font-size: 36px;
		color: $color-a;
		width: 50px;
		padding-top: 8px;
	}

	.icon-box-content {
		padding-top: 18px;
	}
}

/*------/ Space Padding /------*/
.section-t8 {
	padding-top: 8rem;

	@include breakpoint(md_max) {
		padding-top: 4rem;
	}
}

.section-t4 {
	padding-top: 4rem;

	@include breakpoint(md_max) {
		padding-top: 2rem;
	}
}

.section-t3 {
	padding-top: 3rem;
}

.section-md-t3 {
	@include breakpoint(md_max) {
		padding-top: 3rem;
	}
}

.section-b2 {
	padding-bottom: 2rem;
}

.section-tb85 {
	padding: 8rem 0 5rem 0;

	@include breakpoint(md_max) {
		padding: 4rem 0 2.5rem 0;
	}
}

/*------/ Title /------*/
.title-wrap {
	padding-bottom: 4rem;
}

.title-a {
	font-size: 2.6rem;
	font-weight: 600;
}

.title-link {
	font-size: 1.2rem;
	font-weight: 300;
	padding-top: 1.2rem;

	a {
		color: $color-a-light;
	}

	span {
		font-size: 20px;
		padding-left: 4px;
		vertical-align: middle;
	}
}

.title-box-d {
	padding-bottom: 1.8rem;
	margin-bottom: 1rem;
	position: relative;

	.title-d {
		font-weight: 600;
		font-size: 2rem;

		&:after {
			content: '';
			position: absolute;
			width: 70px;
			height: 4px;
			background-color: $color-b;
			bottom: 20px;
			left: 0;
		}
	}
}

/*------/ Display Table & Table Cell /------*/
.display-table {
	width: 100%;
	height: 100%;
	display: table;
}

.table-cell {
	display: table-cell;
	vertical-align: middle;
}

/*------/ Ul Resect /------*/
.ul-resect {
	ul {
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
	}
}

/*------/ Overlay /------*/
.overlay-a {
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 27%, rgba(0, 0, 0, 0.65) 90%);
}

.overlay {
	position: absolute;
	top: 0;
	left: 0px;
	padding: 0;
	height: 100%;
	width: 100%;
}

/*------/ Owl carousel /------*/
.owl-theme {
	.owl-dots {
		text-align: center;
		margin-top: 18px;

		.owl-dot {
			display: inline-block;

			span {
				width: 16px;
				height: 9px;
				margin: 5px 5px;
				background: $color-a;
				border: 0px solid $color-a;
				display: block;
				transition: all 0.6s ease-in-out;
				cursor: pointer;
				border-radius: 0px;
			}

			&:hover {
				span {
					background-color: $color-b;
				}
			}

			&.active {
				span {
					background-color: $color-b;
					width: 35px;
				}
			}
		}
	}
}

.owl-arrow {
	.owl-nav {
		position: absolute;
		top: 0;
		right: 0;
		font-size: 1.8rem;

		.owl-prev,
		.owl-next {
			display: inline-block;
			transition: all 0.6s ease-in-out;
			color: $color-a;

			&.disabled {
				transition: all 0.6s ease-in-out;
				color: $color-d;
			}
		}

		.owl-next {
			margin-left: 15px;
		}
	}
}

.nav-arrow-a {
	.owl-arrow {
		.owl-nav {
			font-size: 1.8rem;
			margin-top: -110px;

			.owl-next {
				margin-left: 15px;
			}
		}
	}
}

.nav-arrow-b {
	.owl-arrow {
		.owl-nav {
			position: absolute;
			top: 0;
			right: 0;
			font-size: 1.2rem;

			.owl-prev,
			.owl-next {
				padding: .7rem 1.5rem !important;
				display: inline-block;
				transition: all 0.6s ease-in-out;
				color: $color-w;
				background-color: $color-b;
				opacity: .9;

				&.disabled {
					transition: all 0.6s ease-in-out;
					color: $color-w;
				}

				&:hover {
					background-color: $color-b-dark;
				}
			}

			.owl-next {
				margin-left: 0px;
			}
		}
	}
}


/* Back to top button */
.back-to-top {
	position: fixed;
	display: none;
	background: $color-b;
	color: #fff;
	width: 44px;
	height: 44px;
	text-align: center;
	line-height: 1;
	font-size: 16px;
	border-radius: 50%;
	right: 15px;
	bottom: 15px;
	transition: background 0.5s;
	z-index: 11;

	i {
		padding-top: 12px;
		color: #fff;
	}

	@media (max-width: 768px) {
		bottom: 15px;
	}
}

/* Prelaoder */
#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	overflow: hidden;
	background: #fff;
}

#preloader:before {
	content: "";
	position: fixed;
	top: calc(50% - 30px);
	left: calc(50% - 30px);
	border: 6px solid #f2f2f2;
	border-top: 6px solid $color-b;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

i[class^=flaticon-]:before,
i[class*=" flaticon-"]:before {
	line-height: 2 !important;	// ridefinito per ottenere la centratura verticale perfetta in flex mode
}

.nr-invisible {
	display: none;
}
