@import "variables";
@import "mixins";

/*======================================
//--//-->   FORM INPUT
======================================*/
.form-a {

	#sendmessage {
		color: $color-b;
		border: 1px solid $color-b-dark;
		display: none;
		text-align: center;
		padding: 15px;
		font-weight: 600;
		margin-bottom: 15px;
	}

	#errormessage {
		color: red;
		display: none;
		border: 1px solid red;
		text-align: center;
		padding: 15px;
		font-weight: 600;
		margin-bottom: 15px;
	}

	#sendmessage.show,
	#errormessage.show,
	.show {
		display: block;
	}

	.validation {
		color: red;
		display: none;
		margin: 4px 0 20px 0;
		font-weight: 400;
		font-size: 13px;
	}

	label {
		color: $color-a;
		position: relative;
	}

	select.form-control-lg:not([size]):not([multiple]) {
		height: 3.5rem;
	}

	.form-control {
		border-radius: 0;
		font-size: 1.1rem;
		font-weight: 300;

		&.form-control-a {
			height: 3.5rem;
		}

		&:focus {
			box-shadow: none;
			border-color: $color-b;
		}
	}
}
