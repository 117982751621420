$flaticon_naar-b2c-icons-font: "flaticon_naar-b2c-icons";

@font-face {
    font-family: $flaticon_naar-b2c-icons-font;
    src: url("./flaticon.ttf?7af9962af3f5cbe5a55b4538a57c0b6b") format("truetype"),
url("./flaticon.woff?7af9962af3f5cbe5a55b4538a57c0b6b") format("woff"),
url("./flaticon.woff2?7af9962af3f5cbe5a55b4538a57c0b6b") format("woff2"),
url("./flaticon.eot?7af9962af3f5cbe5a55b4538a57c0b6b#iefix") format("embedded-opentype"),
url("./flaticon.svg?7af9962af3f5cbe5a55b4538a57c0b6b#flaticon_naar-b2c-icons") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon_naar-b2c-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$flaticon_naar-b2c-icons-map: (
    "africa": "\f101",
    "australia": "\f102",
    "north-america": "\f103",
    "south-america": "\f104",
    "asia": "\f105",
    "india": "\f106",
    "united-arab-emirates": "\f107",
    "peru": "\f108",
    "pyramid": "\f109",
    "oceano-indiano": "\f10a",
    "stati-uniti": "\f10b",
    "polinesia-francese": "\f10c",
    "peru-e-ecuador": "\f10d",
    "sud-est-asiatico": "\f10e",
    "medio-oriente": "\f10f",
    "india-1": "\f110",
    "emirati-arabi": "\f111",
    "cina-e-giappone": "\f112",
    "caraibi": "\f113",
    "australia-e-pacifico": "\f114",
    "africa-1": "\f115",
    "canada": "\f116",
    "europa": "\f117",
);

.flaticon-africa:before {
    content: map-get($flaticon_naar-b2c-icons-map, "africa");
}
.flaticon-australia:before {
    content: map-get($flaticon_naar-b2c-icons-map, "australia");
}
.flaticon-north-america:before {
    content: map-get($flaticon_naar-b2c-icons-map, "north-america");
}
.flaticon-south-america:before {
    content: map-get($flaticon_naar-b2c-icons-map, "south-america");
}
.flaticon-asia:before {
    content: map-get($flaticon_naar-b2c-icons-map, "asia");
}
.flaticon-india:before {
    content: map-get($flaticon_naar-b2c-icons-map, "india");
}
.flaticon-united-arab-emirates:before {
    content: map-get($flaticon_naar-b2c-icons-map, "united-arab-emirates");
}
.flaticon-peru:before {
    content: map-get($flaticon_naar-b2c-icons-map, "peru");
}
.flaticon-pyramid:before {
    content: map-get($flaticon_naar-b2c-icons-map, "pyramid");
}
.flaticon-oceano-indiano:before {
    content: map-get($flaticon_naar-b2c-icons-map, "oceano-indiano");
}
.flaticon-stati-uniti:before {
    content: map-get($flaticon_naar-b2c-icons-map, "stati-uniti");
}
.flaticon-polinesia-francese:before {
    content: map-get($flaticon_naar-b2c-icons-map, "polinesia-francese");
}
.flaticon-peru-e-ecuador:before {
    content: map-get($flaticon_naar-b2c-icons-map, "peru-e-ecuador");
}
.flaticon-sud-est-asiatico:before {
    content: map-get($flaticon_naar-b2c-icons-map, "sud-est-asiatico");
}
.flaticon-medio-oriente:before {
    content: map-get($flaticon_naar-b2c-icons-map, "medio-oriente");
}
.flaticon-india-1:before {
    content: map-get($flaticon_naar-b2c-icons-map, "india-1");
}
.flaticon-emirati-arabi:before {
    content: map-get($flaticon_naar-b2c-icons-map, "emirati-arabi");
}
.flaticon-cina-e-giappone:before {
    content: map-get($flaticon_naar-b2c-icons-map, "cina-e-giappone");
}
.flaticon-caraibi:before {
    content: map-get($flaticon_naar-b2c-icons-map, "caraibi");
}
.flaticon-australia-e-pacifico:before {
    content: map-get($flaticon_naar-b2c-icons-map, "australia-e-pacifico");
}
.flaticon-africa-1:before {
    content: map-get($flaticon_naar-b2c-icons-map, "africa-1");
}
.flaticon-canada:before {
    content: map-get($flaticon_naar-b2c-icons-map, "canada");
}
.flaticon-europa:before {
    content: map-get($flaticon_naar-b2c-icons-map, "europa");
}
