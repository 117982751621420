@import "variables";

/*======================================
//--//-->   BUTTON
======================================*/
.btn {
	transition: .5s ease;
	line-height: $btn-line-height;

	&.btn-a,
	&.btn-b {
		border-radius: 0;
		padding: $btn-padding-h $btn-padding-v;
		letter-spacing: .05rem;
	}

	&.btn-a {
		background-color: $color-a;
		color: $color-w;

		&:hover {
			background-color: $color-b;
			color: $color-a;
		}
	}

	&.btn-b {
		background-color: $color-b;
		color: $color-a;

		&:hover {
			background-color: $color-a;
			color: $color-w;
		}
	}

	&.btn-b-n {
		background-color: $color-b;
		color: $color-w;
		border-radius: 0;

		&:hover {
			background-color: $color-b-dark;
			color: $color-w;
		}
	}
}
